.product-wrapper {
  padding: 30px 10%;
  @media (max-width: 1024px) {
    padding: 30px;
  }
  .product {
    display: grid;
    grid-template-rows: 70% 30%;
    min-height: calc(100vh - 120px);

    // min-height: -webkit-calc(100vh - 120px);
    // min-height:    -moz-calc(100vh - 120px);
    // min-height:         calc(100vh - 120px);
    // background-position: center right;
    // background-repeat: no-repeat;
    // text-align: left;
    @media (max-width: 900px) {
      background-image: none !important;
    }
    .product-header {
      text-align: left;
      background-position: 50%;
      background-size: contain;
      background-repeat: no-repeat;
      img {
        padding: 10px 0;
      }
      h1 {
        margin: 0;
        padding: 0 0 4px 0;
        color: #584544;
        font-family: 'KlavikaMed';
        letter-spacing: 2px;
      }
    }

    .product-detail {
      box-shadow: 0 2px 7px -4px rgba(0,0,0,.75);
      align-self: center;
      padding: 30px 60px;
      justify-self: center;
      background-color: #f7f6f5;
      opacity: .9;
      font-family: AkzidenzGrotesk;
      @media (max-width: 900px) {
        width: auto;
      }
      p {
        margin: 0;
        text-transform: uppercase;
        color: #ae5453;
        font-weight: 700;
        font-size: 22px;
        &.subdetail {
          margin-top: 20px;
          color: #6e5957;
        }
      }
      .product-properties-wrapper {
        margin-top: 20px;
        .product-property {
          display: flex;
          color: #6e5957;
          .product-property-name {
            flex-grow: 1;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}