.header-wrapper {
  position: fixed;
  width: 100vw;
  z-index: 99;
  .header {
    display: flex;
    background-color: #a95352;
    padding: 30px 10%;
    transition: 0.2s ease;

    &.collapse {
      padding: 5px 10%;
      -webkit-box-shadow: 0px 2px 7px -4px rgba(0,0,0,0.75);
      -moz-box-shadow: 0px 2px 7px -4px rgba(0,0,0,0.75);
      box-shadow: 0px 2px 7px -4px rgba(0,0,0,0.75);
      .header-logo {
        height: 50px;
        
      }
      .header-cba-badge {
        display: none;
      }
    }
    .header-logo {
      height: 90px;
      cursor: pointer;
      @media (max-width: 900px) {
        margin: 0 auto;
      }
    }
    .nav {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10%;
      @media (max-width: 900px) {
        display: none;
      }
      .nav-item-wrapper {
        flex: 100px;
        .nav-btn {
          font-family: 'Nexa';
          font-size: 16px;
          font-weight: 700;
          display: inline-block;
          width: 100%;
          background-color: transparent;
          color: #fff;
          text-decoration: none;
          border: none;
          border-right: 1px solid #bbddd4;
          padding: 10px 0;
          text-transform: uppercase;
          outline: none;
          &:hover {
            cursor: pointer;
          }
        }
        &:last-child {
          .nav-btn {
            border-right: none;
          }
        }
      }
    }
    @media (max-width: 900px) {
      .header-cba-badge {
        display: none;
      }
    }
  }
}