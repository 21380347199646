.modal-wrapper {
  &.hidden {
    display: none;
  }
  .modal-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0.3;
    background-color: #000;
  }
  .modal {
    position: absolute;
    top: 50vh;
    left: 50vw;
    //width: 800px;
    width: 400px;
    height: 400px;
    margin-top: -200px;
    //margin-left: -400px;
    margin-left: -200px;
    background-color: #fff;
    opacity: 0.98;
    display: flex;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.75);
    font-family: 'Nexa';
    .btn-close-modal {
      position: absolute;
      left: 0;
      bottom: 0;
      border: none;
      background: #dadada;
      width: 90px;
      height: 35px;
      text-transform: uppercase;
      color: #27272c;
      font-weight: bold;
      letter-spacing: 1px;
      cursor: pointer;
    }
    .left-side-wrapper {
      //flex-basis: 50%;
      text-align: left;
      background: url('../../map.png');
      .left-side {
        padding: 40px;
        color: #494949;
        .title {
          margin: 0;
          font-size: 22px;
          margin-bottom: 7px;
        }
        .subtitle {
          margin: 0;
          font-size: 16px;
          margin-bottom: 40px;
        }
        .contact-item {
          margin: 15px 0;
          display: grid;
          // grid-gap: 1rem;
          grid-template-columns: 40px 1fr;
          grid-template-rows: 20px 20px;
          font-size: 14px;
          .contact-item-icon {
            color: #494949;
            grid-row-start: 1;
            grid-row-end: 4;
          }
        }
      }
    }
    .right-side {
      background-color: #a95352;
      flex-basis: 50%;
      text-align: left;
      .contact-form {
        padding: 40px;
        .form-field {
          margin-bottom: 20px;
          label {
            display: block;
            color: #fff;
          }
          input, textarea {
            width: 100%;
            height: 45px;
            background-color: transparent;
            border: 1px solid #fff;
            border-radius: 5px;
            text-indent: 50px;
            color: #fff;
            font-size: 16px;
            &::placeholder {
              color: #fff;
              font-size: 12px;
              text-indent: 50px;
              text-transform: uppercase;
            }
            &.contact-form-name {
              background: url('../../input-name-icon.png') no-repeat scroll 3px 4px;
            }
            &.contact-form-email {
              background: url('../../input-email-icon.png') no-repeat scroll 3px 4px;
            }
          }
          textarea {
            text-indent: 5px;
            height: 100px;
            &::placeholder {
              text-indent: 5px;
            }
          }
        }
        button {
          width: 100%;
          height: 60px;
          background-color: transparent;
          border: 1px solid #fff;
          color: #fff;
          border-radius: 25px;
          text-transform: uppercase;
          outline: none;
          cursor: pointer;
          &:hover {
            background-color: #fff;
            border: 1px solid #a95352;
            color: #a95352;

          }
        }
      }
    }
  }
}