.footer {
  padding: 40px;
  background-color: #a95352;
  .contact-info {
    margin: 20px 0;
    .contact-info-item {
      margin-bottom: 20px;
      a, p {
        display: block;
        color: #584544;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        font-family: KlavikaMed;
        font-size: 18px;
      }
      a {
        text-decoration: none;
      }
      .contact-info-item-icon {
        margin-bottom: 5px;
        font-size: 18px;
      }
    }
  }
  .social-media {
    margin-top: 30px;
    .social-media-icon {
      margin: 0 15px;
      color: #584544;
      font-size: 30px;
    }
  }
}