.about-us {
	min-height: -webkit-calc(100vh - 120px);
	min-height:    -moz-calc(100vh - 120px);
	min-height:         calc(100vh - 120px);
	background-color: #5a4443;
	padding: 30px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	img {
		width: 595px;

	}
	@media (max-width: 900px) {
		img {
			width: 200px;
		}
	}
	p {
		color: #fff;
		width: 50%;
		margin: 0 auto;
    font-family: 'Nexa';
    font-size: 20px;
		line-height: 30px;
		@media (max-width: 900px) {
      width: 90%;
    }
	}
}