@font-face {
  font-family: 'KlavikaMed';
  src: url('fonts/KlavikaMed-ItalicSC.otf') format('opentype');
}

@font-face {
  font-family: 'AkzidenzGrotesk';
  src: url('fonts/AkzidenzGrotesk-BoldCondAlt.otf') format('opentype');
}

@font-face {
  font-family: 'Nexa';
  src: url('fonts/NexaLight.otf') format('opentype');
}

.App {
  text-align: center;
}

.btn-scroll-to-top {
  position: fixed;
  width: 45px;
  height: 45px;
  right: 15px;
  bottom: 15px;
  border: none;
  background-color: #4a3b3a;
  color: #bbddd4;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  font-size: 20px;
}